"use client";

import Link from "next/link";
import { ArrowDownNarrowWide } from "lucide-react";
import { Select } from "@repo/ui/select";
import { ItemCard } from "@/components/item-card";
import { Layout } from "@/components/layout";
import { ActivityList } from "@/components/activity-list";
import { affixStatFilter, gameplayOptions } from "@/mocks/options";
import { SearchForm, useSearchForm } from "../../components/search-form";

function noop(): string {
  return "";
}

export default function Page(): JSX.Element {
  const form = useSearchForm();

  return (
    <Layout>
      <Layout.Header />
      <Layout.Body>
        <Layout.Left>
          <Layout.LeftAd>
            <div className="flex h-[600px] items-center justify-center rounded-md bg-[#fff] font-medium uppercase text-background">
              Ad
            </div>
          </Layout.LeftAd>
          <Layout.LeftSidebar>
            <Layout.ActivitySidebar>
              <Layout.ActivitySidebar.Header>
                <Layout.ActivitySidebar.Title />
                <Layout.ActivitySidebar.CloseBtn />
              </Layout.ActivitySidebar.Header>
              <Layout.ActivitySidebar.Body>
                <Layout.ActivitySidebar.ActivityFilters />
                <ActivityList>
                  <ActivityList.Item
                    href="/activities/1"
                    rarity="legendary"
                    subType="curative"
                    type="elixir"
                  >
                    <ActivityList.Item.Image />
                    <ActivityList.Item.Title>
                      Legendary item
                    </ActivityList.Item.Title>
                    <ActivityList.Item.Badge />
                  </ActivityList.Item>

                  <ActivityList.Item
                    href="/activities/2"
                    rarity="unique"
                    subType="curative"
                    type="elixir"
                  >
                    <ActivityList.Item.Image />
                    <ActivityList.Item.Title>
                      Unique item
                    </ActivityList.Item.Title>
                    <ActivityList.Item.Badge />
                  </ActivityList.Item>

                  <ActivityList.Item
                    href="/activities/3"
                    rarity="rare"
                    subType="curative"
                    type="elixir"
                  >
                    <ActivityList.Item.Image />
                    <ActivityList.Item.Title>Rare item</ActivityList.Item.Title>
                    <ActivityList.Item.Badge />
                  </ActivityList.Item>

                  <ActivityList.Item
                    href="/activities/4"
                    rarity="magic"
                    subType="curative"
                    type="elixir"
                  >
                    <ActivityList.Item.Image />
                    <ActivityList.Item.Title>
                      Elixir of Furtitudine
                    </ActivityList.Item.Title>
                    <ActivityList.Item.Badge />
                  </ActivityList.Item>
                </ActivityList>
              </Layout.ActivitySidebar.Body>
            </Layout.ActivitySidebar>
          </Layout.LeftSidebar>
        </Layout.Left>
        <Layout.Middle>
          <div className="grid grid-rows-[auto_1fr]  gap-2 overflow-hidden rounded-md bg-gray-900 p-2">
            <SearchForm
              {...form}
              onSubmit={() => {
                noop();
              }}
            >
              <SearchForm.Collapsible>
                <SearchForm.Header>
                  <SearchForm.HeaderLeft>
                    <SearchForm.Select1
                      options={[
                        { label: "Option 1", value: "option_1" },
                        { label: "Option 2", value: "option_2" },
                        { label: "Option 3", value: "option_3" },
                      ]}
                    />
                    <SearchForm.GameplayModeSelect options={gameplayOptions} />
                    <SearchForm.Select3
                      options={[
                        { label: "Option 1", value: "option_1" },
                        { label: "Option 2", value: "option_2" },
                        { label: "Option 3", value: "option_3" },
                      ]}
                    />
                  </SearchForm.HeaderLeft>
                  <SearchForm.HeaderRight>
                    <SearchForm.ClearFilters />
                    <SearchForm.Collapsible.Trigger />
                  </SearchForm.HeaderRight>
                </SearchForm.Header>

                <SearchForm.SearchInput />

                <SearchForm.Collapsible.Content>
                  <SearchForm.FiltersContainer>
                    <SearchForm.GeneralFilters>
                      <SearchForm.GeneralFilters.ItemCategorySelect
                        options={[
                          { label: "Option 1", value: "option_1" },
                          { label: "Option 2", value: "option_2" },
                          { label: "Option 3", value: "option_3" },
                        ]}
                      />
                      <SearchForm.GeneralFilters.ItemRaritySelect
                        options={[
                          { label: "Option 1", value: "option_1" },
                          { label: "Option 2", value: "option_2" },
                          { label: "Option 3", value: "option_3" },
                        ]}
                      />

                      <SearchForm.GeneralFilters.SocketsSelect
                        options={[
                          { label: "Option 1", value: "option_1" },
                          { label: "Option 2", value: "option_2" },
                          { label: "Option 3", value: "option_3" },
                        ]}
                      />

                      <SearchForm.GeneralFilters.ClassSelect
                        options={[
                          { label: "Option 1", value: "option_1" },
                          { label: "Option 2", value: "option_2" },
                          { label: "Option 3", value: "option_3" },
                        ]}
                      />

                      <SearchForm.GeneralFilters.AuctionTypeSelect
                        options={[
                          { label: "Option 1", value: "option_1" },
                          { label: "Option 2", value: "option_2" },
                          { label: "Option 3", value: "option_3" },
                        ]}
                      />

                      <SearchForm.GeneralFilters.ListPeriodSelect
                        options={[
                          { label: "Option 1", value: "option_1" },
                          { label: "Option 2", value: "option_2" },
                          { label: "Option 3", value: "option_3" },
                        ]}
                      />
                      <SearchForm.GeneralFilters.PriceRangeInput />
                      <SearchForm.GeneralFilters.ItemPowerRangeInput />
                      <SearchForm.GeneralFilters.LevelRequirementRangeInput />
                    </SearchForm.GeneralFilters>
                    <SearchForm.StatFilters>
                      {({ statFilters, removeStat }) => {
                        return (
                          <SearchForm.StatFilters.Content>
                            {statFilters.map((statFilter, statIndex) => {
                              return (
                                <SearchForm.StatFilters.StatFilter
                                  affixOptions={affixStatFilter}
                                  key={statFilter.id}
                                  removeStat={removeStat}
                                  statIndex={statIndex}
                                  statType={statFilter.type}
                                >
                                  {({ affixes, removeAffix }) => {
                                    return (
                                      <>
                                        {affixes.map((affix, affixIndex) => {
                                          return (
                                            <SearchForm.StatFilters.StatFilter.Affix
                                              affixIndex={affixIndex}
                                              affixTitle={affix.title}
                                              key={affix.id}
                                              removeAffix={removeAffix}
                                              statIndex={statIndex}
                                            />
                                          );
                                        })}
                                      </>
                                    );
                                  }}
                                </SearchForm.StatFilters.StatFilter>
                              );
                            })}
                          </SearchForm.StatFilters.Content>
                        );
                      }}
                    </SearchForm.StatFilters>
                  </SearchForm.FiltersContainer>
                </SearchForm.Collapsible.Content>
              </SearchForm.Collapsible>
            </SearchForm>

            <div className="flex flex-col gap-4">
              <div className="relative flex h-10 items-center justify-center justify-items-center bg-gray-800">
                <h3 className="font-diablo">
                  Showing 100 Results(100000 Matched)
                </h3>
                <div className="absolute bottom-0 right-0 top-0 w-40">
                  <Select>
                    <Select.Trigger
                      className="h-10 text-base"
                      defaultValue="newest"
                      icon={
                        <Select.Icon>
                          <ArrowDownNarrowWide className="h-4 w-4" />
                        </Select.Icon>
                      }
                    >
                      <Select.Value className="px-4" />
                    </Select.Trigger>
                    <Select.Content>
                      <Select.Group>
                        <Select.Item value="newest">Newest</Select.Item>
                      </Select.Group>
                    </Select.Content>
                  </Select>
                </div>
              </div>

              <div className="relative flex-1">
                <div className="grid grid-cols-3 justify-items-center gap-4">
                  <Link
                    href="/activities/222"
                    key={Math.random()}
                    scroll={false}
                  >
                    <ItemCard
                      rarity="legendary"
                      subType="curative"
                      type="elixir"
                    >
                      <ItemCard.Header>
                        <ItemCard.Image />
                        <ItemCard.Title>Ancestral Legendary</ItemCard.Title>
                        <ItemCard.ItemType>Two-Handed Axe</ItemCard.ItemType>
                      </ItemCard.Header>
                      <ItemCard.Body>
                        <ItemCard.Text1>925 Item power</ItemCard.Text1>
                        <ItemCard.Separator variant="item-power" />
                        <ItemCard.Text1>
                          +56% Damage to Healthy Enemies [56.0]%
                        </ItemCard.Text1>
                        <ItemCard.Separator />
                        <ItemCard.List />
                        <ItemCard.RequirementLevelAuction type="seasonal_softcore">
                          Requires Level 80
                        </ItemCard.RequirementLevelAuction>
                      </ItemCard.Body>
                      <ItemCard.Footer>
                        <ItemCard.Details>
                          <ItemCard.Details.Header>
                            <ItemCard.Details.Title />
                            <ItemCard.Details.Menu />
                          </ItemCard.Details.Header>
                          <ItemCard.Details.Body>
                            <ItemCard.Details.Bid />
                            <ItemCard.Details.Buy />
                          </ItemCard.Details.Body>
                        </ItemCard.Details>
                        <ItemCard.Footer.InformationsUser>
                          <ItemCard.UserAuction>
                            General Mort
                          </ItemCard.UserAuction>
                          <ItemCard.Icon />
                        </ItemCard.Footer.InformationsUser>
                      </ItemCard.Footer>
                    </ItemCard>
                  </Link>

                  <Link
                    href="/activities/222"
                    key={Math.random()}
                    scroll={false}
                  >
                    <ItemCard rarity="magic" subType="curative" type="elixir">
                      <ItemCard.Header>
                        <ItemCard.Image />
                        <ItemCard.Title>Ancestral Legendary</ItemCard.Title>
                        <ItemCard.ItemType>Two-Handed Axe</ItemCard.ItemType>
                      </ItemCard.Header>
                      <ItemCard.Body>
                        <ItemCard.Text1>925 Item power</ItemCard.Text1>
                        <ItemCard.Separator variant="item-power" />
                        <ItemCard.Text1>
                          +56% Damage to Healthy Enemies [56.0]%
                        </ItemCard.Text1>
                        <ItemCard.Separator />
                        <ItemCard.List />
                        <ItemCard.RequirementLevelAuction type="seasonal_softcore">
                          Requires Level 80
                        </ItemCard.RequirementLevelAuction>
                      </ItemCard.Body>
                      <ItemCard.Footer>
                        <ItemCard.Details>
                          <ItemCard.Details.Header>
                            <ItemCard.Details.Title />
                            <ItemCard.Details.Menu />
                          </ItemCard.Details.Header>
                          <ItemCard.Details.Body>
                            <ItemCard.Details.Buy />
                          </ItemCard.Details.Body>
                        </ItemCard.Details>
                        <ItemCard.Footer.InformationsUser>
                          <ItemCard.UserAuction>
                            General Mort
                          </ItemCard.UserAuction>
                          <ItemCard.Icon />
                        </ItemCard.Footer.InformationsUser>
                      </ItemCard.Footer>
                    </ItemCard>
                  </Link>
                  <Link
                    href="/activities/222"
                    key={Math.random()}
                    scroll={false}
                  >
                    <ItemCard rarity="rare" subType="curative" type="elixir">
                      <ItemCard.Header>
                        <ItemCard.Image />
                        <ItemCard.Title>Ancestral Legendary</ItemCard.Title>
                        <ItemCard.ItemType>Two-Handed Axe</ItemCard.ItemType>
                      </ItemCard.Header>
                      <ItemCard.Body>
                        <ItemCard.Text1>925 Item power</ItemCard.Text1>
                        <ItemCard.Separator variant="item-power" />
                        <ItemCard.Text1>
                          +56% Damage to Healthy Enemies [56.0]%
                        </ItemCard.Text1>
                        <ItemCard.Separator />
                        <ItemCard.List />
                        <ItemCard.RequirementLevelAuction type="seasonal_softcore">
                          Requires Level 80
                        </ItemCard.RequirementLevelAuction>
                      </ItemCard.Body>
                      <ItemCard.Footer>
                        <ItemCard.Details>
                          <ItemCard.Details.Header>
                            <ItemCard.Details.Title />
                            <ItemCard.Details.Menu />
                          </ItemCard.Details.Header>
                          <ItemCard.Details.Body>
                            <ItemCard.Details.Buy />
                          </ItemCard.Details.Body>
                        </ItemCard.Details>
                        <ItemCard.Footer.InformationsUser>
                          <ItemCard.UserAuction>
                            General Mort
                          </ItemCard.UserAuction>
                          <ItemCard.Icon />
                        </ItemCard.Footer.InformationsUser>
                      </ItemCard.Footer>
                    </ItemCard>
                  </Link>
                  <Link
                    href="/activities/222"
                    key={Math.random()}
                    scroll={false}
                  >
                    <ItemCard rarity="unique" subType="curative" type="elixir">
                      <ItemCard.Header>
                        <ItemCard.Image />
                        <ItemCard.Title>Ancestral Legendary</ItemCard.Title>
                        <ItemCard.ItemType>Two-Handed Axe</ItemCard.ItemType>
                      </ItemCard.Header>
                      <ItemCard.Body>
                        <ItemCard.Text1>925 Item power</ItemCard.Text1>
                        <ItemCard.Separator variant="item-power" />
                        <ItemCard.Text1>
                          +56% Damage to Healthy Enemies [56.0]%
                        </ItemCard.Text1>
                        <ItemCard.Separator />
                        <ItemCard.List />
                        <ItemCard.RequirementLevelAuction type="seasonal_softcore">
                          Requires Level 80
                        </ItemCard.RequirementLevelAuction>
                      </ItemCard.Body>
                      <ItemCard.Footer>
                        <ItemCard.Details>
                          <ItemCard.Details.Header>
                            <ItemCard.Details.Title />
                            <ItemCard.Details.Menu />
                          </ItemCard.Details.Header>
                          <ItemCard.Details.Body>
                            <ItemCard.Details.Bid />
                            <ItemCard.Details.Buy />
                          </ItemCard.Details.Body>
                        </ItemCard.Details>
                        <ItemCard.Footer.InformationsUser>
                          <ItemCard.UserAuction>
                            General Mort
                          </ItemCard.UserAuction>
                          <ItemCard.Icon />
                        </ItemCard.Footer.InformationsUser>
                      </ItemCard.Footer>
                    </ItemCard>
                  </Link>
                  <Link
                    href="/activities/222"
                    key={Math.random()}
                    scroll={false}
                  >
                    <ItemCard rarity="common" subType="curative" type="elixir">
                      <ItemCard.Header>
                        <ItemCard.Image />
                        <ItemCard.Title>Ancestral Legendary</ItemCard.Title>
                        <ItemCard.ItemType>Two-Handed Axe</ItemCard.ItemType>
                      </ItemCard.Header>
                      <ItemCard.Body>
                        <ItemCard.Text1>925 Item power</ItemCard.Text1>
                        <ItemCard.Separator variant="item-power" />
                        <ItemCard.Text1>
                          +56% Damage to Healthy Enemies [56.0]%
                        </ItemCard.Text1>
                        <ItemCard.Separator />
                        <ItemCard.List />
                        <ItemCard.RequirementLevelAuction type="seasonal_softcore">
                          Requires Level 80
                        </ItemCard.RequirementLevelAuction>
                      </ItemCard.Body>
                      <ItemCard.Footer>
                        <ItemCard.Details>
                          <ItemCard.Details.Header>
                            <ItemCard.Details.Title />
                            <ItemCard.Details.Menu />
                          </ItemCard.Details.Header>
                          <ItemCard.Details.Body>
                            <ItemCard.Details.Buy />
                          </ItemCard.Details.Body>
                        </ItemCard.Details>
                        <ItemCard.Footer.InformationsUser>
                          <ItemCard.UserAuction>
                            General Mort
                          </ItemCard.UserAuction>
                          <ItemCard.Icon />
                        </ItemCard.Footer.InformationsUser>
                      </ItemCard.Footer>
                    </ItemCard>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Layout.Middle>
        <Layout.Right>
          <Layout.RightSidebar />
          <Layout.RightAd>
            <div className="flex h-[600px] items-center justify-center rounded-md bg-[#fff] font-medium uppercase text-background">
              Ad
            </div>
          </Layout.RightAd>
        </Layout.Right>
      </Layout.Body>
    </Layout>
  );
}
